import React from 'react';
// import { Link } from 'gatsby';

import Layout from '../components/layout';
// import Image from '../components/image';
import SEO from '../components/seo';
import Soundbaord from '../apps/sounboard';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Soundbaord />
  </Layout>
);

export default IndexPage;
