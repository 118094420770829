import React, { Component } from 'react';
import sound1 from '../sounds/1.wav';
import sound2 from '../sounds/2.wav';
import sound3 from '../sounds/3.wav';
import sound4 from '../sounds/4.wav';
import sound5 from '../sounds/5.wav';
import sound6 from '../sounds/6.wav';
import sound7 from '../sounds/7.wav';
import sound8 from '../sounds/8.wav';
import sound9 from '../sounds/9.wav';
import sound10 from '../sounds/10.wav';
import sound11 from '../sounds/11.wav';
import sound12 from '../sounds/12.wav';
import sound13 from '../sounds/13.wav';
import sound14 from '../sounds/14.wav';
import sound15 from '../sounds/15.wav';
import sound16 from '../sounds/16.wav';
import sound17 from '../sounds/17.wav';
import sound18 from '../sounds/18.wav';

class Soundboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clips: {
        1: {
          file: sound1,
          title: 'Hello you ****',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        2: {
          file: sound2,
          title: 'Urgh, HARVEYYY',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        3: {
          file: sound3,
          title: 'I broke my back!',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        4: {
          file: sound4,
          title: 'Back is broken!',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        5: {
          file: sound5,
          title: 'Spinal!',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        6: {
          file: sound6,
          title: 'I love it!',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        7: {
          file: sound7,
          title: 'Really so much fun',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        8: {
          file: sound8,
          title: 'B****rd B****h!',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        9: {
          file: sound9,
          title: 'Bloody B****rd ',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        10: {
          file: sound10,
          title: 'Ya F****r!',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        11: {
          file: sound11,
          title: 'You dirty girl',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        12: {
          file: sound12,
          title: 'Any tighter...',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        13: {
          file: sound13,
          title: 'SHET!',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        14: {
          file: sound14,
          title: 'WELL IT WAS F**KIN...',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        15: {
          file: sound15,
          title: 'DISGUSTENN!',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        16: {
          file: sound16,
          title: 'Your dog\'s got tits!',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        17: {
          file: sound17,
          title: 'Bbbbbbebbebb',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
        18: {
          file: sound18,
          title: 'Pffffft',
          isPlaying: false,
          // eslint-disable-next-line no-undef
          audio: null,
        },
      },
    };
  }

  componentDidMount() {
    if (typeof Audio !== 'undefined') {
      const { clips } = this.state;
      // Create new Audio object for each clip
      Object.keys(clips).forEach((key) => {
        const clip = clips[key];
        // eslint-disable-next-line no-undef
        clips[key].audio = new Audio(clip.file);
      });
      // Update clips
      this.setState({
        clips,
      });
    }
  }

  playSound(key) {
    const { clips } = this.state;
    const clip = clips[key];
    const theSound = clip.audio;

    const play = () => {
      theSound.play()
        .then(() => {
          clip.isPlaying = true;
          this.setState({
            clips,
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    };

    const restart = () => {
      // theSound.pause();
      theSound.currentTime = 0;
      theSound.play();
    };

    if (clip.isPlaying) {
      restart();
    } else {
      play();
    }
  }

  render() {
    const { clips } = this.state;
    const clipsKeys = Object.keys(clips);
    return (
      <div className="l-pad-grid">
        {
          clipsKeys.map((key) => (
            <button key={key} type="button" className="e-pad-btn" onClick={() => this.playSound(key)}>
              <span className="e-pad-btn__label">
                {clips[key].title}
              </span>
            </button>
          ))
        }
      </div>
    );
  }
}

export default Soundboard;
